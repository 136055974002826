import React from "react";
import { useFormContext, Controller, UseFormMethods } from "react-hook-form";

import { Models } from "@services/api";
import Typography from "@ui-kit/Typography";
import { OutlinedInput } from "@ui-kit/InputFields";
import { maxLength, minLength } from "@validators";
import { ServerError } from "@models/common";

type BulletinBoardViewProps = {
  facilityView: Models.Facility;
  setFacilityView: React.Dispatch<React.SetStateAction<Models.Facility>>;
};

interface FormFields {
  bulletinOverview: string;
}

export const processServerErrors = (
  errors: ServerError[],
  setError: UseFormMethods["setError"],
  watch: UseFormMethods["watch"],
): boolean => {
  let errorHandled = false;

  errors.forEach((e) => {
    const paths = e.source.pointer?.split("/");

    if (!paths) {
      return false;
    }

    if (paths[1] === "bulletinBoardOverview") {
      switch (e.code) {
        case "validation_length_out_of_range":
          const value = watch("bulletinOverview");

          if (value.length > e.meta?.max) {
            setError("bulletinOverview", {
              message: maxLength(e.meta?.max).maxLength.message,
              type: "manual",
            });
            errorHandled = true;
          }

          if (value.length < e.meta?.min) {
            setError("bulletinOverview", {
              message: minLength(e.meta?.min).minLength.message,
              type: "manual",
            });
            errorHandled = true;
          }

          return true;
      }
    }
  });

  return errorHandled;
};

export const BulletinBoardView: React.FC<BulletinBoardViewProps> = ({
  facilityView,
  setFacilityView,
}) => {
  const { control, errors } = useFormContext<FormFields>();

  return (
    <div data-test="facility-bulletin-board-form">
      <Typography>Add a headline</Typography>

      <OutlinedInput
        value={facilityView.bulletinBoardHeading || ""}
        onChange={(event) => {
          setFacilityView((prev) => ({
            ...prev,
            bulletinBoardHeading: event.target.value,
          }));
        }}
        multiline
        placeholder="What's most important to know?"
        rowsMax="6"
        data-test="heading-field"
        maxChars={50}
      />

      <Typography>Add an overview</Typography>

      <Controller
        render={({ onChange, ...rest }) => (
          <OutlinedInput
            {...rest}
            onChange={(event) => {
              setFacilityView((prev) => ({
                ...prev,
                bulletinBoardOverview: event.target.value,
              }));
              onChange(event);
            }}
            error={errors.bulletinOverview?.message}
            multiline
            placeholder="What is your announcement?"
            rowsMax="6"
            data-test="bulletinOverview-field"
            maxChars={750}
          />
        )}
        name="bulletinOverview"
        defaultValue={facilityView.bulletinBoardOverview || ""}
        control={control}
      />
    </div>
  );
};
