import { createUseStyles } from "react-jss";

import { ITheme } from "@theme";

const styles = (theme: ITheme) => ({
  root: {
    [theme.mediaRequests.notMobile]: {
      width: 450,
    },
  },

  saveButton: {
    marginTop: 20,
  },

  description: {
    marginBottom: 25,
  },

  action: {
    display: "flex",
    justifyContent: "center",
    marginTop: 45,

    "& button": {
      minWidth: 100,
    },
  },
});

export default createUseStyles<ITheme, keyof ReturnType<typeof styles>>(
  styles,
  { name: "EditFacilityBulletinBoard" },
);
