import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";

import Dialog from "@ui-kit/Dialog";
import useStyles from "./styles";
import Button from "@ui-kit/Button";
import SuccessStep from "@components/SuccessStep";
import Typography from "@ui-kit/Typography";
import { useDeepDivePanelStore } from "@hooks";
import { BulletinBoardView, processServerErrors } from "./view";
import { useNotificationStore } from "@store/NotificationStore";
import { goToFormError } from "@helpers/goToFormError";
import { ShadowDivider } from "@components/ShadowDivider";

const Page: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const [{ facility }, { saveFacility }] = useDeepDivePanelStore();
  const [, { setNotification, setUnknownErrorNotification }] =
    useNotificationStore();

  const methods = useForm();
  const { handleSubmit, formState, setError, watch } = methods;
  const [facilityView, setFacilityView] = useState(cloneDeep(facility));
  const [showSuccessfullView, setShowSuccessfullView] = useState(false);
  const classes = useStyles();

  const saveChanges = handleSubmit(async () => {
    try {
      await saveFacility(facilityView);

      setShowSuccessfullView(true);
    } catch (error) {
      let errorHandled = false;

      const errors = error?.response?.data?.errors;

      if (errors) {
        if (processServerErrors(errors, setError, watch)) {
          errorHandled = true;
          goToFormError();
        } else {
          setNotification({
            message: errors[0].title,
            type: "error",
          });
          return;
        }
      }

      if (!errorHandled) {
        setUnknownErrorNotification();
        throw error;
      }
    }
  });

  return (
    <Dialog
      open
      onClose={() => {
        history.replace({
          pathname: `/map/${facility.id}`,
          search,
        });
      }}
      protectQuit={!showSuccessfullView}
      data-test="edit-facility-bulletin-board-dialog"
      tier={facility.subscriptionTier}
      title="School bulletin board"
    >
      <div className={classes.root}>
        {showSuccessfullView ? (
          <SuccessStep
            text="Submitted"
            content={
              <>
                <ShadowDivider />
                <div className={classes.action}>
                  <Button
                    variant="contained"
                    color="primary"
                    data-test="done-button"
                    size="large"
                    onClick={() => {
                      history.replace({
                        pathname: `/map/${facility.id}`,
                        search,
                      });
                    }}
                  >
                    Done
                  </Button>
                </div>
              </>
            }
          />
        ) : (
          <>
            <Typography paragraph className={classes.description}>
              Tell parents about the exciting stuff going on at your school
            </Typography>

            <FormProvider {...methods}>
              <BulletinBoardView
                facilityView={facilityView}
                setFacilityView={setFacilityView}
              />
            </FormProvider>

            <Button
              color="primary"
              size="large"
              variant="contained"
              className={classes.saveButton}
              fullWidth
              data-test="submit-btn"
              onClick={saveChanges}
              loading={formState.isSubmitting}
              disabled={
                !facilityView.bulletinBoardHeading &&
                !facilityView.bulletinBoardOverview
              }
            >
              Submit
            </Button>
          </>
        )}
      </div>
    </Dialog>
  );
};

export default Page;
